<template>
  <th-page-wrapper>
    <!-- Table -->
    <th-datatable
      ref="table"
      :headers="headers"
      do-route
      show-search-filter
      :show-operations="false"
      route-base="/resources/branches"
      resource="branchesV1"
      multiple-select
      force-meta-check
      transform-fetched-meta-allowed
      :resource-query="{ query: { limit: 100, deleted: false } }"
      :buttons="computedButtons"
      :search-filters="filtersList"
      :transform-fetched-data="checkOnboarding"
      @selection-change="handleSelectionChange"
      @loading-error="handleLoadingError"
      @search-filter-submit="handleSubmit"
    >
      <template v-if="canDelete" #actions>
        <div>
          <actions
            :selected-items="selectedItems"
            @delete-requested="handleDelete"
          />
        </div>
      </template>
    </th-datatable>
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { storeToRefs } from 'pinia'
import safeGet from 'just-safe-get'
import qs from 'qs'
import mapValues from 'just-map-values'
import onboardingMixin from '@/mixins/onboarding'
import { mapGetters } from 'vuex'
import Actions from './components/actions'
import { useBranchesStore } from '@/store/branches'
import { useMessagesStore } from '@/store/messages'

export default {
  metaInfo() {
    return {
      title: this.$t('pages.branches.title')
    }
  },
  components: {
    Actions
  },
  setup() {
    const { onboardingConfirmed } = onboardingMixin()
    const branchesStore = useBranchesStore()
    const { branchesCount } = storeToRefs(branchesStore)
    return {
      onboardingConfirmed,
      branchesStore,
      branchesCount
    }
  },
  data() {
    return {
      headers: [
        {
          field: 'name',
          label: this.$t('common.headers.name.title'),
          fallback: '-',
          minWidth: '160',
          truncate: true
        },
        {
          field: 'branch_number',
          label: this.$t('pages.branches.all.headers.branch_number'),
          truncate: true,
          minWidth: '140',
          fallback: '-'
        },
        {
          field: 'street',
          label: this.$t('common.headers.street.title'),
          placeholder: this.$t('common.inputs.placeholders.select'),
          truncate: true,
          minWidth: '160',
          fallback: '-',
          formatter: (row, column) => {
            if (!row || !Array.isArray(row.addresses) || !row.addresses.length)
              return '-'

            // sort in priority and display first
            const order = ['local', 'invoice']
            const localAddress = [...row.addresses].sort(
              (a, b) => order.indexOf(a.type) - order.indexOf(b.type)
            )[0]
            if (!localAddress || !localAddress.street) return '-'

            return `${localAddress.street} ${
              localAddress.street_number || ''
            }`.trim()
          }
        },
        {
          field: 'city',
          label: this.$t('common.headers.city.title'),
          truncate: true,
          fallback: '-',
          formatter: (row, column) => {
            if (!row || !Array.isArray(row.addresses) || !row.addresses.length)
              return '-'

            // sort in priority and display first
            const order = ['local', 'invoice']
            const localAddress = [...row.addresses].sort(
              (a, b) => order.indexOf(a.type) - order.indexOf(b.type)
            )[0]
            if (!localAddress || !localAddress.locality) return '-'

            return localAddress.locality
          }
        },
        {
          field: 'postal_code',
          label: this.$t('pages.branches.all.headers.postal_code'),
          truncate: true,
          fallback: '-',
          formatter: (row, column) => {
            if (!row || !Array.isArray(row.addresses) || !row.addresses.length)
              return '-'

            // sort in priority and display first
            const order = ['local', 'invoice']
            const localAddress = [...row.addresses].sort(
              (a, b) => order.indexOf(a.type) - order.indexOf(b.type)
            )[0]
            if (!localAddress || !localAddress.postal_code) return '-'

            return localAddress.postal_code
          }
        },
        {
          field: 'country',
          label: this.$t('pages.branches.all.headers.country'),
          truncate: true,
          fallback: '-',
          formatter: (row, column) => {
            if (!row || !Array.isArray(row.addresses) || !row.addresses.length)
              return '-'

            // sort in priority and display first
            const order = ['local', 'invoice']
            const localAddress = [...row.addresses].sort(
              (a, b) => order.indexOf(a.type) - order.indexOf(b.type)
            )[0]
            if (!localAddress || !localAddress.country) return '-'

            return localAddress.country
          }
        }
      ],
      filtersList: [
        {
          name: 'name',
          type: 'remote-search-select',
          label: this.$t('common.headers.name.title'),
          resource: 'branches',
          optionsValue: 'name',
          computeName: this.$formatBranch,
          modifyQuery: (q) => ({
            q,
            fields: ['name', 'branch_number'],
            deleted: false
          })
        },
        {
          name: 'branch_group',
          type: 'select',
          label: this.$t('pages.branches.all.headers.group'),
          placeholder: this.$t(
            'components.th_datatable.search_filter.search.placeholder'
          ),
          options: []
        },
        {
          name: 'street',
          type: 'remote-search-select',
          useOld: true,
          label: this.$t('common.headers.street.title'),
          options: [],
          resource: 'branches',
          filterable: true,
          doInitialFetch: false,
          noRepeatKey: 'addresses.street',
          computeName: (branch, q) =>
            this.getAddressesProperty(branch, q, 'street'),
          expandOriginalData: (branch, q) =>
            this.getAddressesProperty(branch, q, 'street'),
          modifyQuery: (q) => ({
            q,
            fields: ['addresses.street']
          })
        },
        {
          name: 'postal_code',
          type: 'remote-search-select',
          useOld: true,
          label: this.$t('pages.branches.all.headers.postal_code'),
          options: [],
          resource: 'branches',
          filterable: true,
          doInitialFetch: false,
          noRepeatKey: 'addresses.postal_code',
          computeName: (branch, q) =>
            this.getAddressesProperty(branch, q, 'postal_code'),
          expandOriginalData: (branch, q) =>
            this.getAddressesProperty(branch, q, 'postal_code'),
          modifyQuery: (q) => ({
            q,
            fields: ['addresses.postal_code']
          })
        },
        {
          name: 'locality',
          type: 'remote-search-select',
          useOld: true,
          label: this.$t('common.headers.city.title'),
          options: [],
          resource: 'branches',
          filterable: true,
          doInitialFetch: false,
          noRepeatKey: 'addresses.locality',
          computeName: (branch, q) =>
            this.getAddressesProperty(branch, q, 'locality'),
          expandOriginalData: (branch, q) =>
            this.getAddressesProperty(branch, q, 'locality'),
          modifyQuery: (q) => ({
            q,
            fields: ['addresses.locality']
          })
        }
      ],
      buttons: [
        {
          type: 'create',
          scopes: ['resources:branches:create']
        }
      ],
      selectedItems: []
    }
  },
  computed: {
    ...mapGetters({
      isOnboardingRoute: 'Config/getIsOnboardingRoute'
    }),
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    canDelete() {
      return this.$checkPermissions({ scopes: ['resources:branches:delete'] })
    }
  },
  mounted() {
    this.fetchFilterResources()
  },
  methods: {
    handleSelectionChange(val) {
      this.selectedItems = val
    },
    handleClose() {
      this.$router.push({ name: 'resources-branches' })
    },
    checkOnboarding(data) {
      if (
        this.isOnboardingRoute &&
        !this.onboardingConfirmed('branches') &&
        data.length === 1
      ) {
        this.$router.push(`/resources/branches/${data[0].id}`)
      }
      if (this.isOnboardingRoute) {
        // Unset onboarding once entered in the page
        this.$store.dispatch('Config/setIsOnboardingRoute', false)
      }
      return data
    },
    refresh() {
      this.handleClose()
      this.$refs.table.refresh()
    },
    async handleDelete(payload) {
      const confirm = await this.$askToDeleteMany(
        payload,
        this.$t('common.resource.branch.plural').toLowerCase(),
        'name'
      )
      if (confirm) {
        await this.deleteBranch(payload)
        this.$store.dispatch('Config/setBranchCurrencies', { vm: this })
      }
    },
    async deleteBranch(payload) {
      if (this.selectedItems.length > 1) {
        this.handleBulkDelete(this.selectedItems)
        return
      }
      const successMessage = this.$t('common.success.action.delete.single', {
        resource: this.$t('common.resource.branch.singular')
      })
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.branch.singular')
      })

      try {
        const inst = th.branches()
        await inst.delete(payload[0].id)
        this.$message({
          type: 'success',
          message: successMessage
        })

        this.updateBranchesCount()

        this.refresh()
      } catch (err) {
        this.$logException(err, { message: errorMessage })
      }
    },
    handleBulkDelete(items) {
      const inst = th.branches()

      const operations = items.map((item) => {
        return () => inst.delete(item.id)
      })

      const label = this.$t('common.error.action.delete.multiple', {
        resources: this.$t('common.resource.branch.plural')
      })

      const currentRoute = this.$route.fullPath

      const fulfillment = () => {
        this.updateBranchesCount()
        if (this.$route.fullPath === currentRoute) {
          this.refresh()
        }
      }

      useMessagesStore().startLocalOperation({
        operations,
        label,
        fulfillment
      })
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('common.resource.branch.plural')
        })
      })
    },
    handleSubmit(filters) {
      const filterQueries = Object.entries(filters).reduce(
        (accFiltersResult, currentFilter) => {
          const [key, valueObj] = currentFilter
          return {
            ...accFiltersResult,
            [key]:
              safeGet(valueObj, 'originalData.queryInput') || valueObj.value
          }
        },
        {}
      )
      this.makeQuery(filterQueries)
    },
    makeQuery(v) {
      const q = { ...this.parsedQuery, ...v }

      const filter = qs.stringify({
        filter: mapValues(q, (value, key) => {
          if (value === null || value === undefined) return undefined
          return value
        })
      })

      const route = `${this.$route.path}?${filter}`
      this.$router.push(route)
    },
    async fetchFilterResources() {
      try {
        const { branchGroups } = await this.$resourceFetch('branchGroups')

        this.filtersList = this.filtersList.map((filter) => {
          let options = []
          if (filter.name === 'branch_group') {
            options = branchGroups.map((group) => ({
              label: group.name,
              value: group.id
            }))
          }
          return options.length ? { ...filter, options: options } : filter
        })
      } catch (err) {
        this.handleLoadingError(err)
      }
    },
    getAddressesProperty(branch, q, prop) {
      return (branch.addresses || []).find((address) =>
        address[prop].toLowerCase().includes(q.toLowerCase())
      )[prop]
    },
    updateBranchesCount() {
      this.branchesStore.checkBranchesCount()
    }
  }
}
</script>
